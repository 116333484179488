import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Box, VStack, Text, useColorModeValue, useBreakpointValue } from "@chakra-ui/react";

const ProfessorsModal = ({ isOpen, onClose, course }) => {
    let professors = course?.professors;

    // Remove duplicates and sort in alphabetical order
    professors = [...new Set(professors)].sort();

    const bgColor = useColorModeValue('gray.200', 'gray.600');
    const textColor = useColorModeValue('black', 'white');

    const modalWidth = useBreakpointValue({ base: '90%', sm: '70%', md: '50%', lg: '30%' });

    function replaceNegativeNumber(str) {
        // Split the string by ':' and trim any whitespace
        const parts = str.split(':').map(part => part.trim());

        // Get the last part of the string, which should be the number
        const numberStr = parts[parts.length - 1];

        // Convert the string to a number
        const number = parseFloat(numberStr);

        // Check if the number is negative
        if (number < 0) {
            // Replace the last part with "Not rated"
            parts[parts.length - 1] = "Not rated";
            // Join the parts back together with ':'
            return parts.join(': ');
        }

        // I the number is not negative, return the original string
        return str + " / 5";
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="md" isCentered>
            <ModalOverlay style={{ backdropFilter: "blur(2px)", backgroundColor: "rgba(0, 0, 0, 0.2)" }} />
            <ModalContent maxW={modalWidth} maxH="90vh" color={textColor}>
                <ModalHeader>
                    <Text as="strong">{course.id}</Text>
                    <br />
                    Instructor Names & Ratings
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody overflowY="auto" maxH="80vh">
                    {professors && professors.length > 0 ? (
                        <VStack align="start" spacing={4} pr={2} width="100%" pb={4}> {/* Adding padding-bottom */}
                            {professors.map((professor, index) => (
                                <Box key={index} p={2} bg={bgColor} borderRadius="md" width="100%">
                                    {replaceNegativeNumber(professor)} {/* Modify the professor string */}
                                </Box>
                            ))}
                        </VStack>
                    ) : (
                        <Text>No professor data</Text>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default ProfessorsModal;
